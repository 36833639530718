<template>
  <div class="com">
    <el-button type="text" @click="displayDialog = true">
      <i class="el-icon-edit"></i>
    </el-button>

    <el-dialog :title="title" :visible.sync="displayDialog">
      <template>
        <el-input v-model="inputData"></el-input>
        <div class="toolbar">
          <el-button @click="displayDialog = false" type="default"
            >取消</el-button
          >

          <el-button @click="handleClickOK" type="primary">确定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "NumberInputDailogButton",

  props: {
    value: Number,
    title: String,
  },

  data() {
    return {
      displayDialog: false,
      inputData: "",
    };
  },

  watch: {
    value: function (a) {
      this.inputData = a;
    },
  },

  methods: {
    handleClickOK() {
      let text = this.inputData;
      this.fireOK(new Number(text));
      this.displayDialog = false;
    },

    fireOK(value) {
      this.$emit("input", value);
    },
  },
};
</script>


<style scoped>
.com {
  display: inline-block;
}

.toolbar {
  text-align: right;
  margin-top: 30px;
}
</style>