<template>
  <div class="view">
    <h1>DateTime</h1>

    <table class="table">
      <tr>
        <th></th>
        <th>时刻A</th>
        <th>时刻B</th>
        <th>时长 (时刻B - 时刻A)</th>
      </tr>
      <tr>
        <td>yyyy-MM-dd HH:mm:ss</td>
        <td>
          <el-date-picker
            v-model="timeA"
            type="datetime"
            placeholder="选择日期时间"
            align="right"
            value-format="timestamp"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </td>
        <td>
          <el-date-picker
            v-model="timeB"
            type="datetime"
            placeholder="选择日期时间"
            align="right"
            value-format="timestamp"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </td>
        <td>{{ timeA2Bstring }}</td>
      </tr>
      <tr>
        <!-- td>输出 ("yyyy-MM-dd HH:mm:ss.SSS")</td -->
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>ms from 1970-01-01</td>
        <td>
          {{ timeA }}
          <NumberInputDialogButton
            :value="timeA"
            @input="handleInputNumberA"
            title="请输入：时刻A"
          />
        </td>
        <td>
          {{ timeB }}
          <NumberInputDialogButton
            :value="timeB"
            @input="handleInputNumberB"
            title="请输入：时刻B"
          />
        </td>
        <td>
          {{ timeA2B }}
          <NumberInputDialogButton
            :value="timeA2B"
            title="请输入：时长"
            @input="handleInputNumberA2B"
          />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import NumberInputDialogButton from "./number-input-dialog-button";

export default {
  name: "DateTime",
  components: { NumberInputDialogButton },

  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },

      timeA: 0,
      timeB: 0,
    };
  },

  computed: {
    timeA2B() {
      return this.timeB - this.timeA;
    },

    timeA2Bstring() {
      let ms = new Number(this.timeB - this.timeA);
      let flag = "";
      if (ms < 0) {
        flag = "-";
        ms = -ms;
      }

      let day = ms / (1000 * 3600 * 24);
      let hh = (ms / (3600 * 1000)) % 24;
      let mm = (ms / (60 * 1000)) % 60;
      let ss = (ms / 1000) % 60;
      let sss = ms % 1000;

      let str = flag;
      str = this.formatNumber(str, day, 0, "天, ");
      str = this.formatNumber(str, hh, 2, ":");
      str = this.formatNumber(str, mm, 2, ":");
      str = this.formatNumber(str, ss, 2, ".");
      str = this.formatNumber(str, sss, 3, "");
      return str;
    },
  },

  methods: {
    handleInputNumberA(value) {
      this.timeA = value;
    },

    handleInputNumberB(value) {
      this.timeB = value;
    },

    handleInputNumberA2B(value) {
      let a = this.timeA;
      // let b = this.timeB;
      this.timeB = a + value;
    },

    formatNumber(prefix, n, width, suffix) {
      n = Math.floor(n);
      let str = "" + n;
      for (; str.length < width; ) {
        str = "0" + str;
      }
      return prefix + str + suffix;
    },
  },
};
</script>


<style scoped>
.view {
  padding: 30px;
  background-color: lightgray;
}

.table {
  margin-left: auto;
  margin-right: auto;
}
</style>